import indexStyles from '../sass/index.scss';
import category1IMG from '../img/category-1.png';
import category2IMG from '../img/category-2.png';
import category3IMG from '../img/category-3.png';
import category4IMG from '../img/category-4.png';
import category5IMG from '../img/category-5.png';
import category6IMG from '../img/category-6.png';
import category7IMG from '../img/category-7.png';
import category8IMG from '../img/category-8.png';
import category9IMG from '../img/category-9.png';
import category10IMG from '../img/category-10.png';
